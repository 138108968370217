/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { RegistrationWithPin } from "./components/RegistrationWithPin";
import { ConfirmRegistration } from "./components/ConfirmRegistration";
import { LoginWithPin } from "./components/LoginWithPin";

import { ForgotPassword } from "./components/ForgotPassword";
import { HeaderWrapper } from "../../../_metronic/layout/components/header";
import { useAuth } from "./core/Auth";
import { ForgotAcc } from "./components/ForgotAcc";
import { LoginWithPass } from "./components/LoginWithPass";
import { RegistrationWithPass } from "./components/RegistrationWithPass";

const AuthLayout = () => {
  useEffect(() => {
    document.body.classList.add("bg-body");
    return () => {
      document.body.classList.remove("bg-body");
    };
  }, []);

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        background: "#fff",
      }}
    >
      <HeaderWrapper />

      {/* begin::Content */}
      <div className="d-flex  flex-column flex-column-fluid p-lg-10 pb-lg-20 m-auto mt-sm-10 mt-md-15">
        {/* begin::Wrapper */}
        <div className="mw-lg-600px bg-body rounded p-8 p-lg-20 mx-lg-20 d-flex flex-center">
          <Outlet />
        </div>
        {/* end::Wrapper */}
      </div>
    </div>
  );
};

const AuthPage = () => {
  const { isAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuth && location.pathname.includes("/registration")) {
      navigate("/auth");
    }
  }, [isAuth, location]);

  //добавить логин - пин!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path="login" element={<LoginWithPin />} />

        <Route path="login-pass" element={<LoginWithPass />} />

        <Route path="registration">
          <Route index element={<RegistrationWithPin />} />
          <Route path="confirm" element={<ConfirmRegistration />} />
        </Route>

        <Route path="registration-pass">
          <Route index element={<RegistrationWithPass />} />
          <Route path="confirm" element={<ConfirmRegistration />} />
        </Route>

        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="forgot-acc" element={<ForgotAcc />} />
        <Route index element={<LoginWithPin />} />
      </Route>
    </Routes>
  );
};

export { AuthPage };
