import { FC, lazy, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";
import { WalletPage } from "../modules/wallet/WalletPage";
import { AdsPage } from "../modules/ads/AdsPage";

const PrivateRoutes = () => {
  const DealPage = lazy(() => import("../modules/deal/DealPage"));
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const ChatPage = lazy(() => import("../modules/chat/ChatPage"));

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/deals/create" />} />
        {/* Pages */}
        <Route
          path="deals/*"
          element={
            <SuspensedView>
              <DealPage />
            </SuspensedView>
          }
        />

        <Route
          path="wallet/*"
          element={
            <SuspensedView>
              <WalletPage />
            </SuspensedView>
          }
        />

        <Route
          path="account/*"
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        <Route
          path="ads/*"
          element={
            <SuspensedView>
              <AdsPage />
            </SuspensedView>
          }
        />

        {/* <Route
          path="chat/*"
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        /> */}

        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
