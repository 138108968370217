import React, { useEffect, useState } from "react";
import { map } from "lodash";
import { Pagination } from "../../components/Pagination";
import { convertCentsToDollars } from "../../../utils";
import { OverlayTrigger } from "react-bootstrap";
import { KTIcon } from "../../../../_metronic/helpers";
import { useIntl } from "react-intl";
import { TransactionHistory } from "../core/_models";

type Props = {
  className: string;
  getTransaction: (props: any) => void;
  dataTransaction?: TransactionHistory[];
  isWalletTransactionsLoading: boolean;
  title: string;
  subTitle: string;
  tooltip?: any;
  showColorsAndSigns: boolean;
};

const itemsValue = 10;
const startCurrentItemsValue = 0;

const basicComment = "-";

export const WalletTransactions: React.FC<Props> = ({
  className,
  getTransaction,
  dataTransaction,
  isWalletTransactionsLoading,
  title,
  subTitle,
  tooltip,
  showColorsAndSigns,
}) => {
  const intl = useIntl();

  const [currentItemsValue, setCurrentItemsValue] = useState(
    startCurrentItemsValue
  );
  const [prevDataTable, setPrevDataTable] = useState<any>([]);

  useEffect(() => {
    const fetchData = () => {
      getTransaction({ currentItemsValue, itemsValue });
    };

    fetchData();

    const intervalId = setInterval(fetchData, 15000);

    return () => clearInterval(intervalId);
  }, [currentItemsValue, itemsValue]);

  const processComment = (comment: string) => {
    if (!comment) {
      return basicComment;
    }

    if (comment.includes("списание")) {
      return comment
        .replace(
          /^Ручное\s/,
          intl.formatMessage({ id: "TRANSACTION_MANUAL" }) + " "
        )
        .replace(
          "списание",
          intl.formatMessage({ id: "TRANSACTION_SPENDING" })
        );
    } else if (comment.includes("Вывод средств с баланса кошелька")) {
      return comment.replace(
        "Вывод средств с баланса кошелька",
        intl.formatMessage({ id: "TRANSACTION_WITHDRAWAL_BALANCE" }) + " "
      );
    } else if (comment.includes("Внос обеспечения за арбитража по сделке")) {
      return comment.replace(
        "Внос обеспечения за арбитража по сделке",
        intl.formatMessage({ id: "TRANSACTION_ARBITRATION_DEPOSIT" }) + " "
      );
    } else if (comment.includes("Внос обеспечения за арбитраж по сделке")) {
      return comment.replace(
        "Внос обеспечения за арбитраж по сделке",
        intl.formatMessage({ id: "TRANSACTION_ARBITRATION_DEPOSIT" }) + " "
      );
    } else if (comment.includes("Комиссия по сделке")) {
      return comment.replace(
        "Комиссия по сделке",
        intl.formatMessage({ id: "TRANSACTION_FEE" }) + " "
      );
    } else if (comment.includes("Коммисия за арбитраж по сделке")) {
      return comment.replace(
        "Коммисия за арбитраж по сделке",
        intl.formatMessage({ id: "TRANSACTION_FEE_ARBITER" }) + " "
      );
    } else if (comment.includes("Обеспечение по сделке")) {
      return comment.replace(
        "Обеспечение по сделке",
        intl.formatMessage({ id: "TRANSACTION_DEPOSIT_DEAL" }) + " "
      );
    } else if (comment.includes("Оплата по сделке")) {
      return comment.replace(
        "Оплата по сделке",
        intl.formatMessage({ id: "TRANSACTION_PAYMENT_DEAL" }) + " "
      );
    } else if (comment.includes("Пополнение баланса")) {
      return comment.replace(
        "Пополнение баланса",
        intl.formatMessage({ id: "TRANSACTION_MANUAL_REPLENISHMENT" }) + " "
      );
    } else if (comment.includes("Ручное списание баланса")) {
      return comment.replace(
        "Ручное списание баланса",
        intl.formatMessage({ id: "TRANSACTION_MANUAL_WITHDRAWAL" }) + " "
      );
    } else if (comment.includes("Обеспечение комиссии по сделке")) {
      return comment.replace(
        "Обеспечение комиссии по сделке",
        intl.formatMessage({ id: "TRANSACTION_COMMISSION_DEPOSIT" }) + " "
      );
    } else if (comment.includes("Выплата по сделке")) {
      return comment.replace(
        "Выплата по сделке",
        intl.formatMessage({ id: "TRANSACTION_WITHDRAWAL_DEAL" }) + " "
      );
    } else {
      return comment;
    }
  };

  useEffect(() => {
    if (!dataTransaction) {
      return;
    }

    const filteredData = dataTransaction.filter(
      (item) => item.comment !== "Application for withdrawal of funds"
    );

    const result = filteredData
      .filter((item) => item.amount !== 0)
      .map((item) => ({
        date: item.date,
        amount: item.amount,
        operation: item.operation,
        comment: processComment(item.comment),
      }));

    if (JSON.stringify(result) !== JSON.stringify(prevDataTable)) {
      setPrevDataTable(result);
    }
  }, [dataTransaction]);

  const renderCommentWithLinks = (comment: any) => {
    if (!comment) {
      return <span dangerouslySetInnerHTML={{ __html: basicComment }} />;
    }

    // Регулярные выражения для поиска uuid и txid
    const uuidRegex =
      /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g;
    const txidRegex = /txid:\s([0-9a-f]{64})/g;

    // Замена UUID на ссылки
    let result = comment.replace(
      uuidRegex,
      (match: string) =>
        `<a class="link mb-1" href="/deals/deal-card/${match}" >
      <span class="link d-none d-sm-block">
        ${match}
      </span>
      <span class="link d-block d-sm-none">
        ${match.substring(0, 8)}...
      </span>
    </a>`
    );

    // Замена TXID на ссылки
    result = result.replace(
      txidRegex,
      (match: string, txid: string) =>
        `<a class="link mb-1" href="https://tronscan.org/#/transaction/${txid}" >
      <span class="link d-none d-sm-block">
        ${txid}
      </span>
      <span class="link d-block d-sm-none">
        ${txid.substring(0, 8)}...
      </span>
    </a>`
    );

    result = result.replace(
      /Пополнение баланса,\s?50,000\s?USDT,?\s?/,
      "Пополнение баланса"
    );

    return <span dangerouslySetInnerHTML={{ __html: result }} />;
  };

  return (
    <div className={`card ${className} mb-5 mb-xl-10`}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column w-50">
          <div className="d-flex mb-1 align-items-center">
            <span className="card-label fw-bold fs-3 me-2">{title}</span>{" "}
            {tooltip && (
              <OverlayTrigger
                key="copy-to-clipboard"
                placement="top"
                overlay={tooltip}
              >
                <button
                  type="button"
                  className="btn btn-icon btn-outline-secondary btn-sm p-0"
                >
                  <KTIcon
                    iconName="question"
                    className="fs-1 text-muted me-1"
                  />
                </button>
              </OverlayTrigger>
            )}
          </div>
          {/* <span className="text-muted mt-1 fw-semibold fs-7">{subTitle}</span> */}
        </h3>
        <div className="card-toolbar">
          <Pagination
            currentItemsValue={currentItemsValue}
            setCurrentItemsValue={setCurrentItemsValue}
            itemsValue={itemsValue}
            startCurrentItemsValue={startCurrentItemsValue}
            data={dataTransaction}
          />
        </div>
      </div>
      <div className="card-body py-3">
        <div className="table-responsive">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bold">
                <th className="min-w-xl-150px">
                  {intl.formatMessage({
                    id: "TABLE.HEADER.DATE",
                    defaultMessage: "Дата",
                  })}
                </th>
                <th className="min-w-xl-150px">
                  {intl.formatMessage({
                    id: "TABLE.HEADER.AMOUNT",
                    defaultMessage: "Сумма",
                  })}
                </th>
                <th className="min-w-xl-150px">
                  {intl.formatMessage({
                    id: "TABLE.HEADER.COMMENT",
                    defaultMessage: "Комментарий",
                  })}
                </th>
              </tr>
            </thead>
            <tbody>
              {/* {isWalletTransactionsLoading && (
                <tr className="text-dark fw-bold fs-6">
                  <td colSpan={3}>
                    <span>...</span>
                  </td>
                </tr>
              )} */}
              {!isWalletTransactionsLoading && !prevDataTable?.length && (
                <tr className="text-muted fs-6">
                  <td colSpan={3}>
                    <span>{intl.formatMessage({ id: "NO_DATA" })}</span>
                  </td>
                </tr>
              )}
              {prevDataTable &&
                map(prevDataTable, (item: TransactionHistory, idx) => {
                  const isWithdrawal = item.operation === "withdrawal";
                  const amountSign = isWithdrawal ? "-" : "+";
                  const amountClass = showColorsAndSigns
                    ? isWithdrawal
                      ? "text-danger"
                      : "text-success"
                    : "text-muted";

                  return (
                    item && (
                      <tr key={idx}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span className="text-muted  fs-7 fw-bold">
                                {new Date(item.date).toLocaleString(undefined, {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className={`${amountClass} fs-7 fw-bold ms-3`}>
                            {showColorsAndSigns && amountSign}
                            {convertCentsToDollars(Number(item.amount))}
                            {<small>$</small>}
                          </span>
                        </td>
                        <td>
                          <span className="text-muted fs-7 fw-bold">
                            {renderCommentWithLinks(item.comment)}
                          </span>
                        </td>
                      </tr>
                    )
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
