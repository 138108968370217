import React, { useEffect, useState } from "react";
import { map } from "lodash";
import { Pagination } from "../../components/Pagination";
import { convertCentsToDollars, getTronTransactionId } from "../../../utils";
import { useIntl } from "react-intl";
import { WithdrawHistory } from "../core/_models";
import { useCancelWithdraw, useWithdrawTransactions } from "../core/_requests";
import { BadgeStatusDeal } from "../../deal/FinalCard/components/BadgeStatusDeal";
import { ToastTopLeft } from "../../components/ToastTopLeft";

interface Props {
  className?: string;
}

const itemsValue = 10;
const startCurrentItemsValue = 0;

export const WithdrawTransactions: React.FC<Props> = ({ className }) => {
  const intl = useIntl();

  const [currentItemsValue, setCurrentItemsValue] = useState(
    startCurrentItemsValue
  );
  const [prevDataTable, setPrevDataTable] = useState<any>([]);

  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  const {
    mutate: getTransaction,
    data: dataTransaction,
    isLoading: isWithdrawTransactionsLoading,
  } = useWithdrawTransactions();

  const {
    mutate: calcelTransaction,
    isLoading: isCalcelTransactionsLoading,
    error: dataCalcelError,
  } = useCancelWithdraw();

  useEffect(() => {
    if (dataCalcelError) {
      setShowErrorToast(true);
    }
  }, [dataCalcelError]);

  useEffect(() => {
    const fetchData = () => {
      getTransaction({ currentItemsValue, itemsValue });
    };

    fetchData();

    const intervalId = setInterval(fetchData, 15000);

    return () => clearInterval(intervalId);
  }, [currentItemsValue, itemsValue]);

  useEffect(() => {
    if (!dataTransaction) {
      return;
    }

    const result = dataTransaction.map((item) => ({
      date: item.date,
      amount: item.amount,
      comment: `${
        translateComment(item.comment)
          ? `${translateComment(item.comment)} <br />`
          : ""
      } ${intl.formatMessage({ id: "PROFILE_WITHDRAW" })}: ${item.requisites} ${
        item.tx_id
          ? `<br /> ${intl.formatMessage({
              id: "WALLET_TRANSACTION_TXID",
            })}: <a href="https://tronscan.org/#/transaction/${item.tx_id}" target="_blank">${item.tx_id}</a>`
          : "" /* ${item.tx_id} ${getTronTransactionId(
              item.tx_id
            )} */
      }`,
      status: item.status,
      request_uuid: item.request_uuid,
    }));

    if (JSON.stringify(result) !== JSON.stringify(prevDataTable)) {
      setPrevDataTable(result);
    }
  }, [dataTransaction]);

  const translateComment = (comment: string): string => {
    switch (comment) {
      case "Application for withdrawal of funds":
        return intl.formatMessage({ id: "WITHDRAW.REQUEST_TITLE" });
      case "Пользователь отменил запрос":
        return intl.formatMessage({ id: "WITHDRAW.CANCELLED_REQUEST" });
      case "Заявка одобрена":
        return intl.formatMessage({ id: "TRANSACTION_APPLICATION_APPROVED" });
      case "Заявка на вывод средств":
        return intl.formatMessage({ id: "TRANSACTION_WITHDRAWAL_OF_FUNDS" });

      default:
        return comment;
    }
  };

  const renderCommentWithLinks = (comment: string) => {
    return <span dangerouslySetInnerHTML={{ __html: comment }} />;
  };

  const handleCancelRequest = (uuid: string) => {
    calcelTransaction(uuid, {
      onSuccess: () => {
        setShowSuccessToast(true);
        getTransaction({ currentItemsValue, itemsValue });
      },
    });
  };

  return (
    <div className={`card ${className} mb-5 mb-xl-10`}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column w-50">
          <div className="d-flex mb-1 align-items-center">
            <span className="card-label fw-bold fs-3 me-2">
              {intl.formatMessage({ id: "WITHDRAW.REQUESTS_TITLE" })}
            </span>
          </div>
        </h3>
        <div className="card-toolbar">
          <Pagination
            currentItemsValue={currentItemsValue}
            setCurrentItemsValue={setCurrentItemsValue}
            itemsValue={itemsValue}
            startCurrentItemsValue={startCurrentItemsValue}
            data={dataTransaction}
          />
        </div>
      </div>
      <div className="card-body py-3">
        <div className="table-responsive">
          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
              <tr className="fw-bold">
                <th className="min-w-xl-150px">
                  {intl.formatMessage({
                    id: "TABLE.HEADER.DATE",
                    defaultMessage: "Дата",
                  })}
                </th>
                <th className="min-w-xl-150px">
                  {intl.formatMessage({
                    id: "TABLE.HEADER.AMOUNT",
                    defaultMessage: "Сумма",
                  })}
                </th>
                <th className="min-w-xl-150px">
                  {intl.formatMessage({
                    id: "TABLE.HEADER.COMMENT",
                    defaultMessage: "Комментарий",
                  })}
                </th>
                <th className="min-w-xl-150px">
                  {intl.formatMessage({
                    id: "TABLE.HEADER.STATUS",
                    defaultMessage: "Статус",
                  })}
                </th>
              </tr>
            </thead>
            <tbody>
              {/* {isWithdrawTransactionsLoading && (
                <tr className="text-dark fw-bold fs-6">
                  <td colSpan={4}>
                    <span>...</span>
                  </td>
                </tr>
              )} */}
              {!isWithdrawTransactionsLoading && !prevDataTable?.length && (
                <tr className="text-muted fs-6">
                  <td colSpan={4}>
                    <span>{intl.formatMessage({ id: "NO_DATA" })}</span>
                  </td>
                </tr>
              )}
              {prevDataTable &&
                map(prevDataTable, (item: WithdrawHistory, idx) => {
                  return (
                    item && (
                      <tr key={idx}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="d-flex justify-content-start flex-column">
                              <span className="text-muted  fs-7 fw-bold">
                                {new Date(item.date).toLocaleString(undefined, {
                                  year: "numeric",
                                  month: "numeric",
                                  day: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className={`text-success fs-7 fw-bold ms-3`}>
                            {convertCentsToDollars(Number(item.amount))}
                            {<small>$</small>}
                          </span>
                        </td>
                        <td>
                          <span className="text-muted fs-7 fw-bold">
                            {renderCommentWithLinks(item.comment)}
                          </span>
                        </td>
                        <td>
                          <div className="text-muted fs-7 fw-bold mb-3 me-3">
                            <BadgeStatusDeal status={item.status} />
                          </div>

                          {/* {item.status === "new" && (
                            <button
                              className="btn btn-danger btn-sm text-center"
                              onClick={() => {
                                handleCancelRequest(item.request_uuid);
                              }}
                              disabled={isCalcelTransactionsLoading}
                            >
                              {intl.formatMessage({
                                id: "WITHDRAW.GET_OUT",
                              })}
                            </button>
                          )} */}
                        </td>
                      </tr>
                    )
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>

      <ToastTopLeft
        showToast={showSuccessToast}
        setShowToast={setShowSuccessToast}
        state="success"
      />
      <ToastTopLeft
        showToast={showErrorToast}
        setShowToast={setShowErrorToast}
        state="danger"
      />
    </div>
  );
};
