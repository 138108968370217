/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PasswordMeterComponent } from "../../../../_metronic/assets/ts/components";
import { useIntl } from "react-intl";
import { useAuth } from "../core/Auth";
import rulesInfoPdf from "../../../../../src/_metronic/assets/files/rulesInfo.pdf";
import confidendalPolicyPdf from "../../../../../src/_metronic/assets/files/confidendal-policy.pdf";
import { removeAllSpaces } from "../../../../_metronic/helpers";
import { ToastTopLeft } from "../../components/ToastTopLeft";

// Регулярное выражение
const regex = /^[A-Za-z0-9!@#$%^&*()_+\-={}[\]:;"'<>,.?]+$/;
const regexPass =
  /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+\-={}[\]:;"'<>,.?]{8,}$/;

const initialValues = {
  nickname: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
  showPassword: false,
  showConfirmPassword: false,
};

export function RegistrationWithPass() {
  const [refLink, setReflink] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentReferralLinkStatus, setCurrentReferralLinkStatus] = useState<{
    status: string;
    text: string;
  } | null>(null);

  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);

  // const [currentPromocodeStatus, setCurrentPromocodeStatus] = useState<{
  //   status: string;
  //   text: string;
  // } | null>(null);

  // const [token, setToken] = useState<any>();
  // const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  const { saveAuth } = useAuth();
  const intl = useIntl();
  const navigate = useNavigate();

  const { refLinkParams } = useParams();

  useEffect(() => {
    if (refLink === null) {
      setCurrentReferralLinkStatus({
        status: "error",
        text: "Реферальная ссылка не активна. Регистрация невозможна.",
      });
    }
  }, [refLink]);

  useEffect(() => {
    const referral_link = localStorage.getItem("reflink");

    if (refLinkParams) {
      setReflink(refLinkParams);
    } else if (referral_link) {
      setReflink(referral_link);
    }
  }, [refLinkParams]);

  const fetchCheckLinks = async () => {
    // try {
    //   const response = await checkLinks(refLink);
    //   setCurrentReferralLinkStatus({
    //     status: "success",
    //     text: "Реферальная ссылка активна.",
    //   });
    //   localStorage.setItem("reflink", refLink as string);
    // } catch (error) {
    //   // setCurrentReferralLinkStatus(null);
    //   // navigate("/error/404");
    //   // setCurrentReferralLinkStatus({
    //   //   status: "error",
    //   //   text: "Реферальная ссылка не активна",
    //   // });
    //   localStorage.removeItem("reflink");
    //   setReflink(null);
    // }
  };

  useEffect(() => {
    if (refLink) {
      setCurrentReferralLinkStatus({
        status: "loading",
        text: "Проверяем реферальную ссылку",
      });

      fetchCheckLinks();
    }
  }, [refLink]);

  const registrationSchema = Yup.object().shape({
    nickname: Yup.string()
      .min(
        3,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 3 })
      )
      .max(20, "Максимальная длина поля 20 символов")
      .matches(regex, "Имя пользователя не должно содержать спецсимволы")
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),
    password: Yup.string()
      .min(
        8,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 8 })
      )
      .max(
        50,
        intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 50 })
      )
      .matches(
        regexPass,
        "Пароль должен содержать минимум 8 символов, состоять из букв и цифр и может включать спецсимволы"
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })),

    changepassword: Yup.string()
      .min(
        8,
        intl.formatMessage({ id: "AUTH.VALIDATION.MIN_LENGTH" }, { length: 8 })
      )
      .max(
        50,
        intl.formatMessage({ id: "AUTH.VALIDATION.MAX_LENGTH" }, { length: 50 })
      )
      .matches(
        regexPass,
        "Пароль должен содержать минимум 8 символов, состоять из букв и цифр и может включать спецсимволы"
      )
      .required(intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" }))
      .oneOf(
        [Yup.ref("password")],
        intl.formatMessage({ id: "AUTH.VALIDATION.PASSWORD_MISMATCH" })
      ),
    acceptTerms: Yup.bool().required(
      intl.formatMessage({ id: "AUTH.VALIDATION.TERMS_REQUIRED" })
    ),
    // promo: Yup.string().required(
    //   intl.formatMessage({ id: "AUTH.VALIDATION.REQUIRED_FIELD" })
    // ),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      // if (!refLink) {
      //   setCurrentReferralLinkStatus({
      //     status: "error",
      //     text: "Для регистрации нужна реферальная ссылка",
      //   });
      //   return;
      // }

      setLoading(true);

      // if (values?.promo) {
      //   try {
      //     const checkPromocodeData = await сheckPromocode(values.promo);

      //     if (checkPromocodeData?.status === "success") {
      //       setCurrentPromocodeStatus(null);
      //     } else {
      //       setCurrentPromocodeStatus({
      //         status: "error",
      //         text: "Такого промокода не существует",
      //       });
      //       setSubmitting(false);
      //       setLoading(false);
      //       return;
      //     }
      //   } catch (error) {
      //     console.error("Ошибка при проверке промокода:", error);
      //     setCurrentPromocodeStatus({
      //       status: "error",
      //       text: "Ошибка при проверке промокода",
      //     });
      //     setSubmitting(false);
      //     setLoading(false);
      //     return;
      //   }
      // }

      try {
        // const { data: auth } = await register(
        //   values.email,
        //   values.nickname,
        //   values.password,
        //   // values.promo,
        //   // token,
        //   refLink
        // );
        // setRefreshReCaptcha((r) => !r);

        // saveAuth(auth);
        // const { data: user } = await getUserByToken();
        // setCurrentUser(user);
        // navigate("/auth/login", {
        //   state: { action: { type: "SUCCESS_REGISTERED" } },
        // });

        setShowSuccessToast(true);
        setTimeout(() => {
          navigate("/auth");
        }, 3000);
      } catch (error: any) {
        console.error(error);
        setShowErrorToast(true);
        // saveAuth(undefined);
        let statusText = intl.formatMessage({
          id: "AUTH.VALIDATION.WRONG_CREDITINALS",
        });

        if (error) {
          statusText =
            error?.response?.data?.detail?.[0]?.msg ||
            error?.response?.data?.detail;
        }

        setStatus(statusText);
        setSubmitting(false);
        setLoading(false);
        // setRefreshReCaptcha((r) => !r);
      }
    },
  });

  useEffect(() => {
    if (formik?.values?.nickname) {
      formik.values.nickname = removeAllSpaces(formik?.values?.nickname);
    }
  }, [formik?.values?.nickname]);

  useEffect(() => {
    if (formik?.values?.password) {
      formik.values.password = removeAllSpaces(formik?.values?.password);
    }
  }, [formik?.values?.password]);

  useEffect(() => {
    if (formik?.values?.changepassword) {
      formik.values.changepassword = removeAllSpaces(
        formik?.values?.changepassword
      );
    }
  }, [formik?.values?.changepassword]);

  useEffect(() => {
    PasswordMeterComponent.bootstrap();
    /* do something like submit a form and then refresh recaptcha */
  }, []);

  return (
    <>
      {/* <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_GOOGLE_PUBLIC_KEY || ""}
      > */}
      <form
        className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
        noValidate
        id="kt_login_signup_form"
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className="text-center mb-11">
          {/* begin::Title */}
          <h1 className="text-dark fw-bolder mb-3">Регистрация</h1>
          {/* end::Title */}

          {/* <div className="text-gray-500 fw-semibold fs-6">
              Your Social Campaigns
            </div> */}
        </div>
        {/* end::Heading */}

        {/* <div className="separator separator-content my-14">
          <span className="text-gray-500 fw-semibold fs-7">
            Для регистрации необходима реферальная ссылка
          </span>
        </div> */}

        {/* {formik.status && (
          <div className="mb-lg-10 alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )} */}
        {/* 
        {currentReferralLinkStatus && (
          <div
            className={clsx("mb-lg-10 alert", {
              "alert-danger": currentReferralLinkStatus.status === "error",
              "alert-success": currentReferralLinkStatus.status === "success",
              "alert-info": currentReferralLinkStatus.status === "loading",
              "alert-warning": currentReferralLinkStatus.status === "warning",
            })}
          >
            <div className="alert-text font-weight-bold">
              {currentReferralLinkStatus.status === "loading" && (
                <span className="spinner-border spinner-border-sm align-middle ms-2 me-2"></span>
              )}
              {currentReferralLinkStatus.text}
            </div>
          </div>
        )} */}

        {/* {currentPromocodeStatus && (
          <div
            className={clsx("mb-lg-10 alert", {
              "alert-danger": currentPromocodeStatus.status === "error",

              "alert-info": currentPromocodeStatus.status === "loading",
            })}
          >
            <div className="alert-text font-weight-bold">
              {currentPromocodeStatus.status === "loading" && (
                <span className="spinner-border spinner-border-sm align-middle ms-2 me-2"></span>
              )}
              {currentPromocodeStatus.text}
            </div>
          </div>
        )} */}

        {/* <GoogleReCaptcha
          onVerify={setToken}
          refreshReCaptcha={refreshReCaptcha}
        /> */}

        {/* begin::Form group nickname */}
        <div className="fv-row mb-8">
          {/* <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({ id: "AUTH.INPUT.USERNAME.TITLE" })}
          </label> */}
          <input
            placeholder={intl.formatMessage({
              id: "AUTH.INPUT.USERNAME.TITLE",
            })}
            type="text"
            autoComplete="off"
            {...formik.getFieldProps("nickname")}
            className={clsx(
              "form-control bg-transparent",
              {
                "is-invalid": formik.touched.nickname && formik.errors.nickname,
              },
              {
                "is-valid": formik.touched.nickname && !formik.errors.nickname,
              }
            )}
          />
          {formik.touched.nickname && formik.errors.nickname && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.nickname}</span>
              </div>
            </div>
          )}
        </div>

        {/* begin::Form group Password */}
        <div className="fv-row mb-5" data-kt-password-meter="true">
          <div className="mb-1">
            {/* <label className="form-label fw-bolder text-dark fs-6">
              {intl.formatMessage({ id: "AUTH.INPUT.PASSWORD.TITLE" })}
            </label> */}
            <div className="position-relative mb-3">
              <input
                type={formik.values.showPassword ? "text" : "password"}
                placeholder={intl.formatMessage({
                  id: "AUTH.INPUT.PASSWORD.PLACEHOLDER",
                })}
                autoComplete="off"
                {...formik.getFieldProps("password")}
                className={clsx(
                  "form-control bg-transparent",
                  {
                    "is-invalid":
                      formik.touched.password && formik.errors.password,
                  },
                  {
                    "is-valid":
                      formik.touched.password && !formik.errors.password,
                  }
                )}
              />
              <span
                className="btn btn-sm btn-icon position-absolute translate-middle top-0 mt-7 end-0 me-3"
                onClick={() =>
                  formik.setFieldValue(
                    "showPassword",
                    !formik.values.showPassword
                  )
                }
              >
                <i
                  className={`bi fs-2 ${
                    formik.values.showPassword
                      ? "bi-eye-slash-fill"
                      : "bi-eye-fill"
                  }`}
                ></i>
              </span>
              {formik.touched.password && formik.errors.password && (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            {/* begin::Meter */}
            <div
              className="d-flex align-items-center mb-3"
              data-kt-password-meter-control="highlight"
            >
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
              <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
            </div>
            {/* end::Meter */}
          </div>
          <div className="text-muted">
            {intl.formatMessage(
              { id: "AUTH.VALIDATION.MIN_LENGTH_TITLE" },
              { length: 8 }
            )}
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group Confirm password */}
        <div className="fv-row mb-8">
          {/* <label className="form-label fw-bolder text-dark fs-6">
            {intl.formatMessage({ id: "AUTH.INPUT.CONFIRM_PASSWORD" })}
          </label> */}
          <div className="position-relative">
            <input
              type={formik.values.showConfirmPassword ? "text" : "password"}
              placeholder={intl.formatMessage({
                id: "AUTH.INPUT.CONFIRM_PASSWORD",
              })}
              autoComplete="off"
              {...formik.getFieldProps("changepassword")}
              className={clsx(
                "form-control bg-transparent",
                {
                  "is-invalid":
                    formik.touched.changepassword &&
                    formik.errors.changepassword,
                },
                {
                  "is-valid":
                    formik.touched.changepassword &&
                    !formik.errors.changepassword,
                }
              )}
            />
            <span
              className="btn btn-sm btn-icon position-absolute translate-middle top-0 mt-7 end-0 me-3"
              onClick={() =>
                formik.setFieldValue(
                  "showConfirmPassword",
                  !formik.values.showConfirmPassword
                )
              }
            >
              <i
                className={`bi fs-2 ${
                  formik.values.showConfirmPassword
                    ? "bi-eye-slash-fill"
                    : "bi-eye-fill"
                }`}
              ></i>
            </span>
            {formik.touched.changepassword && formik.errors.changepassword && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  <span role="alert">{formik.errors.changepassword}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* end::Form group */}

        {/* begin::Form group PROMO */}
        {/* <div className="fv-row mb-8">
          
          <input
            type="text"
            placeholder={intl.formatMessage({
              id: "AUTH.INPUT.PROMO",
            })}
            autoComplete="off"
            {...formik.getFieldProps("promo")}
            className={clsx(
              "form-control bg-transparent bg-light-info text-info"
            )}
          />
          {formik.touched.promo && formik.errors.promo && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.promo}</span>
              </div>
            </div>
          )}
        </div> */}
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="fv-row mb-8">
          <label
            className="form-check form-check-inline"
            htmlFor="kt_login_toc_agree"
          >
            <input
              className="form-check-input"
              type="checkbox"
              id="kt_login_toc_agree"
              {...formik.getFieldProps("acceptTerms")}
            />
            <span>
              Я подтверждаю, что ознакомлен(-на), согласен(-на) и принимаю{" "}
              <a href="/faq" className="fw-bold text-cashback text-hover-black">
                {intl.formatMessage({ id: "FOOTER.TERMS_AND_CONDITIONS" })}
              </a>
              .
            </span>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                <span role="alert">{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className="text-center">
          <button
            type="submit"
            id="kt_sign_up_submit"
            className="btn btn-lg btn-primary w-100 mb-5"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms ||
              !refLink
            }
          >
            {!loading && (
              <span className="indicator-label">
                {intl.formatMessage({
                  id: "AUTH.GENERAL.REGISTER_BUTTON",
                })}
              </span>
            )}
            {/* {!token && (
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            )} */}
            {loading && (
              <span className="indicator-progress" style={{ display: "block" }}>
                {intl.formatMessage({
                  id: "GLOBAL.WAIT",
                })}{" "}
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            )}
          </button>
          <Link reloadDocument to="/">
            <button
              type="button"
              id="kt_login_signup_form_cancel_button"
              className="btn btn-lg btn-light-primary w-100 mb-5"
            >
              {intl.formatMessage({
                id: "AUTH.GENERAL.BACK_BUTTON",
              })}
            </button>
          </Link>
        </div>

        <ToastTopLeft
          showToast={showSuccessToast}
          setShowToast={setShowSuccessToast}
          state="success"
        />
        <ToastTopLeft
          showToast={showErrorToast}
          setShowToast={setShowErrorToast}
          state="danger"
        />
        {/* end::Form group */}
      </form>
      {/* </GoogleReCaptchaProvider> */}
    </>
  );
}
